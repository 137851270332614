import React from "react"
import { graphql } from "gatsby"
import AppBar from '../../components/appBar'
import Img from "gatsby-image"
import Typography from '@material-ui/core/Typography';


const Advdesc = ({ data: { prismicAdventuredescrition } }) => {
  const { data } = prismicAdventuredescrition
  return (
    <React.Fragment>
      <AppBar />
      <Typography variant="h1" >
        {data.adventuretitle.text}
      </Typography>

      <Img
        fluid={data.adventureimage.localFile.childImageSharp.fluid}
      />

      <Typography variant="body1" >

        {data.adventuredescription.text}
      </Typography>
      <Typography variant="body1" >

        {data.adventuredescription.text}
      </Typography>
      <Typography variant="body1" >

        {data.adventuredescription.text}
      </Typography>
      <Typography variant="body1" >

        {data.adventuredescription.text}
      </Typography>
      <Img
        fluid={data.adventureimage.localFile.childImageSharp.fluid}
      />

      <Typography variant="body1" >

        {data.adventuredescription.text}
      </Typography>

    </React.Fragment>
  )
}

export default Advdesc

export const advQuery = graphql`
  query AdventureDescription($uid: String!){
    prismicAdventuredescrition (uid: { eq: $uid }){
      uid
      data {
        adventuretitle {
  
          text
        }
        adventureimage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 400, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        adventuredescription {
  
          text
        }
      }
    }
  }
  
  `